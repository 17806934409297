import React, { ReactElement, useEffect, useState } from 'react';
import { Modal, Form, Input, Space, Select, Checkbox, Divider, InputNumber, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {
  selectEditHwcWareHouseFeeModal,
  selectHwcWarehouseFeeModalLoading,
  setEditHwcWarehouseFeeModal,
  updateHwcWarehouseFeeHandler
} from '../../../../redux/hwcWarehouseFeeSlice';
import { HwcWarehouseFee } from '../../../../custom_types/redux-types';
import { WeightUnit } from '../../../../shared/utils/constants';

interface HwcWarehouseFeeEditModalProps {
  hwcWarehouseFee: HwcWarehouseFee;
  onCancel: () => void;
}

const { Option } = Select;

const HwcWarehouseFeeEditModal = ({
  hwcWarehouseFee,
  onCancel
}: HwcWarehouseFeeEditModalProps): ReactElement => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const showModal = useSelector(selectEditHwcWareHouseFeeModal);
  const modalLoading = useSelector(selectHwcWarehouseFeeModalLoading);

  useEffect(() => {
    form.setFieldsValue(hwcWarehouseFee);
  }, [form, dispatch, hwcWarehouseFee]);

  const cancelClickedHandler = () => {
    form.resetFields();
    dispatch(setEditHwcWarehouseFeeModal(false));
    onCancel();
  };

  const okClickedHandler = () => {
    form
      .validateFields()
      .then((values) => {
        const data: HwcWarehouseFee = {
          ...hwcWarehouseFee,
          weightRange: values.weightRange,
          weightUnit: values.weightUnit,
          baseFee: values.baseFee,
          countingFee: values.countingFee,
          skuFee: values.skuFee,
          packingFee: values.packingFee,
          labelFee: values.labelFee,
          returnFee: values.returnFee,
          storageFee: values.storageFee
        };
        dispatch(updateHwcWarehouseFeeHandler(hwcWarehouseFee.id, data));
        form.resetFields();
        onCancel();
      })
      .catch(() => {});
  };

  return (
    <Modal
      width={600}
      styles={{
        body: {
          minHeight: '300px',
          maxHeight: '1000px',
          overflowY: 'auto',
          margin: '10px 50px'
        }
      }}
      centered
      closable={false}
      open={showModal}
      okText="编辑仓库操作费"
      cancelText="取消"
      onCancel={cancelClickedHandler}
      onOk={okClickedHandler}
      okButtonProps={{ loading: modalLoading }}
    >
      <Form form={form} layout="vertical">
        <Space size="large">
          <Form.Item label="重量范围" name="weightRange">
            <Input placeholder="重量范围" />
          </Form.Item>
          <Form.Item label="重量单位" name="weightUnit">
            <Select placeholder="重量单位" style={{ width: 180 }}>
              {Object.values(WeightUnit).map((item) => (
                <Option key={item} value={item}>
                  {item}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Space>
        <div>
          <Space size="large">
            <Form.Item
              label="基础费用"
              name="baseFee"
              rules={[{ required: true, message: '基础费用必须填' }]}
            >
              <InputNumber placeholder="基础费用" />
            </Form.Item>
            <Form.Item
              label="清点费"
              name="countingFee"
              rules={[{ required: true, message: '清点费用必须填' }]}
            >
              <InputNumber placeholder="清点费" />
            </Form.Item>
          </Space>
        </div>
        <div>
          <Space size="large">
            <Form.Item
              label="多SKU费用"
              name="skuFee"
              rules={[{ required: true, message: '多SKU费用必须填' }]}
            >
              <InputNumber placeholder="多SKU费用" />
            </Form.Item>
            <Form.Item
              label="包装费"
              name="packingFee"
              rules={[{ required: true, message: '包装费必须填' }]}
            >
              <InputNumber placeholder="包装费" />
            </Form.Item>
          </Space>
        </div>
        <div>
          <Space size="large">
            <Form.Item
              label="自提面单费"
              name="labelFee"
              rules={[{ required: true, message: '自提面单费必须填' }]}
            >
              <InputNumber placeholder="自提面单费" />
            </Form.Item>
            <Form.Item
              label="退货费"
              name="returnFee"
              rules={[{ required: true, message: '退货费必须填' }]}
            >
              <InputNumber placeholder="退货费" />
            </Form.Item>
          </Space>
        </div>
        <Form.List name="storageFee">
          {(fields, { add, remove }) => (
            <>
              <Divider>仓储费</Divider>
              {fields.map(({ key, name, ...restField }) => (
                <Space key={key} size="large">
                  <Form.Item {...restField} label="体积" name={[name, 'volumnRange']}>
                    <Input placeholder="体积范围" />
                  </Form.Item>
                  <Form.Item {...restField} label="费用" name={[name, 'fee']}>
                    <InputNumber placeholder="费用" />
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Space>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  style={{ width: '60%' }}
                  icon={<PlusOutlined />}
                >
                  添加仓储费
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </Modal>
  );
};

export default HwcWarehouseFeeEditModal;
